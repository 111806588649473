import React from "react";
import WELCOME from "../assets/images/welcome.png";

export default function About() {
  return (
    <div
      style={{
        display: "flex",
        marginRight: "auto",
        marginLeft: "auto",
        width: "75%",
        padding: 50,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img src={WELCOME} width="300" />
      <div className="text-container">
        <p className="welcome-text-one">A little about us</p>
        <h1 className="welcome-text-two">COMMITTED TO EXCELLENCE</h1>
        <p className="welcome-text-three">
          Welcome to MedicalTrainingHub.com, your premier destination for
          comprehensive healthcare education. At Medical Training Hub, we are
          committed to equipping you with the essential skills and knowledge
          necessary for a rewarding career in the medical field. Our carefully
          crafted courses cover a spectrum of critical disciplines, including
          Certified Nursing Assistant (CNA) training, life-saving CPR
          certification, expert Phlebotomy instruction & Electrocardiogram
          Technician (EKG). With a focus on hands-on learning and expert
          guidance, our courses are designed to prepare you for the challenges
          and opportunities of the healthcare industry. Join us on the path to
          making a difference in people's lives, one skill at a time. Enroll
          today and embark on your journey to becoming a confident and
          compassionate healthcare professional.
        </p>
      </div>
    </div>
  );
}
