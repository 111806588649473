import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import '../assets/styles/Courses.css'
import { Link } from "react-router-dom";

function Courses() {
  const [isRed, setisRed] = useState(false);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setisRed((prevIsRed) => !prevIsRed);
    }, 500);
    return () => {
      clearInterval(intervalId);
    };
  }, []);
  return (
    <Container>
      <div className="row-main">
        <div className="crs-info">
          <div className="image-container botomy">
            <p className="new-price">
              <b style={isRed ? { color: "red" } : null}> Summer's special: <br/>Was $1549, now $1249! </b>
            </p>
          </div>
          <h2 className="heading" style={{ fontSize: 28 }}>
            PHLEBOTOMY TRAINING
          </h2>
          <p className="crs-text">
            Phlebotomists collect blood for medical purposes, like laboratory
            testing, transfusions etc. ensuring patient comfort
            and safety.
          </p>

          <Link className="none" to={"/phlebotomy"}>
            <p className="read-more-new">Read More!</p>
          </Link>
        </div>

        <div className="crs-info">
          <div className="image-container cpr">
            <p className="new-price">
              <b>Price:$99</b>
            </p>
          </div>
          <h2 className="heading">CPR TRAINING</h2>
          <p className="crs-text">
            CPR training teaches life-saving skills for emergencies when
            someone's heart or breathing has stopped.
          </p>
          <Link className="none" to={"/cpr"}>
            <p className="read-more-new">Read More!</p>
          </Link>
        </div>

        <div className="crs-info">
          <div className="image-container cna">
            <p className="new-price">
              <b>Coming Soon!</b>
            </p>
          </div>
          <h2 className="heading">CNA TRAINING</h2>
          <p className="crs-text">
            CNAs provide basic patient care in healthcare settings under RN or
            LPN supervision, including hospitals and nursing homes.
          </p>
          <Link className="none" to={"/cna"}>
            <p className="read-more-new">Read More!</p>
          </Link>
        </div>

        <div className="crs-info">
          <div className="image-container ekg">
            <p className="new-price">
              <b>Coming Soon!</b>
            </p>
          </div>
          <h2 className="heading">EKG TECHNICIAN</h2>
          <p className="crs-text">
            EKG technicians use specialized equipment to monitor heart activity
            in healthcare settings, working under RN or LPN supervision.
          </p>
          <Link className="none" to={"/ekg"}>
            <p className="read-more-new">Read More!</p>
          </Link>
        </div>
      </div>
    </Container>
  );
}

export default Courses;
