import React, { useState, useEffect } from "react";
// import { Paper, Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import "../assets/styles/AllCourses.css";
import EKGimg from "../assets/images/ekg_training.jpg";
import Container from "react-bootstrap/Container";

export default function AllCourses() {
  const [isRed, setisRed] = useState(false);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setisRed((prevIsRed) => !prevIsRed);
    }, 500);
    return () => {
      clearInterval(intervalId);
    };
  }, []);
  return (
    <Container>
      <div className="container text-center">
        <div className="heading">
          <h1>Below Are the Courses Being Offered</h1>
        </div>
      </div>
      <div className="all_container">
        <div className="crs-info-new">
          <img
            className="img_all_course"
            src="https://images.unsplash.com/photo-1631815590058-860e4f83c1e8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fE51cnNlfGVufDB8fDB8fHww&auto=format&fit=crop&w=400&q=60"
          />
          <h2>PHLEBOTOMY TRAINING</h2>
          <p>
            Phlebotomy training teaches you the skills and knowledge necessary
            to draw blood from patients. You will learn about the anatomy and
            physiology of the circulatory system, the different types of blood
            tests, and the proper technique for drawing blood. In the hands-on
            practice, you will have the opportunity to draw blood from live
            patients under the supervision of a certified phlebotomist.
            <br />
            <span style={{ margin: 0 }}>
              Classes will be on Tuesday's & Wednesday's from (4PM - 9PM) & Every other
              Saturday's for clinicals
            </span>
            <br />
            <b className={isRed ? "red" : "black"}>
            Summer's special: <br/>Was $1549, now $1249!
            </b>
            <br />
            <br />
            <Link to={"/phlebotomy"} style={{ textDecorationLine: "none" }}>
              <a className="read-more">Read More</a>
            </Link>
          </p>
        </div>
        <div className="crs-info-new">
          <img
            className="img_all_course"
            src="https://images.unsplash.com/photo-1630964046403-8b745c1e3c69?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8Q1BSfGVufDB8fDB8fHww&auto=format&fit=crop&w=400&q=60"
          />
          <h2>CPR TRAINING</h2>
          <p>
            CPR training is a program that teaches you the skills and knowledge
            necessary to perform cardiopulmonary resuscitation (CPR). CPR is a
            life-saving technique that can be used to help people who are
            experiencing cardiac arrest. In the classroom, you will learn about
            the anatomy and physiology of the heart, the signs and symptoms of
            <br /> cardiac arrest, and the proper technique for performing CPR.
            <br />
            <br />
            <b>
              <i> $99</i>
            </b>
            <br />
            <br />
            <Link to={"/cpr"} style={{ textDecorationLine: "none" }}>
              <a className="read-more">Read More</a>
            </Link>
          </p>
        </div>
        <div className="crs-info-new">
          <img
            className="img_all_course"
            src="https://images.unsplash.com/photo-1612776572997-76cc42e058c3?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MzN8fE51cnNlfGVufDB8fDB8fHww&auto=format&fit=crop&w=400&q=60"
          />
          <h2>CNA TRAINING</h2>
          <p>
            CNA training is a program that teaches students the skills and
            knowledge necessary to work as a certified nursing assistant (CNA).
            CNAs provide basic care to patients in hospitals, nursing homes, and
            other healthcare settings. They help patients with activities of
            daily living, such as bathing, dressing, and eating, and they also
            provide basic nursing care, such as taking vital signs and assisting
            with medication administration.
            <br />
            <br />
            <b>
              Price:<i> Coming Soon</i>
            </b>
            <br />
            <br />
            <Link to={"/cna"} style={{ textDecorationLine: "none" }}>
              <a className="read-more">Read More</a>
            </Link>
          </p>
        </div>

        <div className="crs-info-new">
          <img className="img_all_course" src={EKGimg} />
          <h2>EKG TECHNICIAN</h2>
          <p>
            EKG Technician training focuses on mastering the skills and
            knowledge needed to perform Electrocardiogram (EKG) tests. This
            program covers the fundamentals of cardiovascular physiology, EKG
            placement, and interpretation. You'll gain practical experience by
            administering EKG tests to real patients under the guidance of
            experienced EKG technicians or healthcare professionals.
            <br />
            <br />
            <b>
              Price:<i>Coming Soon </i>
            </b>
            <br />
            <br />
            <Link to={"/ekg"} style={{ textDecorationLine: "none" }}>
              <a className="read-more">Read More</a>
            </Link>
          </p>
        </div>
      </div>
    </Container>
  );
}
