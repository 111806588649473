import "../assets/styles/Home.css";
import Hero from "../components/Hero";
import Courses from "../components/Courses";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import WELCOME from "../assets/images/welcome.png";

function Home() {
  return (
    <>
      <Hero />
      <Container>
        <Row>
          <Col>
            <div className="welcome-image-container">
              <img src={WELCOME} alt="" className="welcome-img" />
            </div>
          </Col>
          <Col>
            <div className="text-container">
              <p className="welcome-text-one">A little about us</p>
              <h1 className="welcome-text-two">COMMITTED TO EXCELLENCE</h1>
              <p className="welcome-text-three">
                Welcome to MedicalTrainingHub.com, your premier destination for
                comprehensive healthcare education. At Medical Training Hub, we
                are committed to equipping you with the essential skills and
                knowledge necessary for a rewarding career in the medical field.
                Our carefully crafted courses cover a spectrum of critical
                disciplines, including Certified Nursing Assistant (CNA)
                training, life-saving CPR certification, expert Phlebotomy
                instruction & Electrocardiogram Technician (EKG). With a focus
                on hands-on learning and expert guidance, our courses are
                designed to prepare you for the challenges and opportunities of
                the healthcare industry. Join us on the path to making a
                difference in people's lives, one skill at a time. Enroll today
                and embark on your journey to becoming a confident and
                compassionate healthcare professional.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      
      <div className="course-start-text">
        <p className="course-start-text-para">Phlebotomy class starting on July 23rd, 2024</p>
        <p className="course-start-text-para">Registration last date July 21st, 2024</p>
      </div>
      <Courses />
    </>
  );
}

export default Home;
