import React from "react";
import PHONE from "../assets/images/svgs/phone-svg.svg";
import EMAIL from "../assets/images/svgs/email.svg";

export default function Contact() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 50,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
      >
        <h1>Contact Us Here</h1>
        <div>
          <div style={{ display: "flex" }}>
            <img src={PHONE} width={40} style={{ marginRight: 20 }} />
            <h2> Call Us:</h2>
          </div>
          <a href="tel:8477862437">
            <h3 style={{ marginTop: 0 }}>877-204-6232</h3>
          </a>
        </div>
        <div>
          <div style={{ display: "flex" }}>
            <img src={EMAIL} width={40} style={{ marginRight: 20 }} />
            <h2 style={{ marginTop: 25 }}>Write to us:</h2>
          </div>
          <a href="mailto:recipient@example.com">

          <h3 style={{ marginTop: 0 }}>
              Info@medicaltraininghub.com
          </h3>
          </a>
        </div>
      </div>
    </div>
  );
}
